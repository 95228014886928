import { DISCUSSION_VIEW_SELECTOR } from "@/store/modules/discussion/state.js";
import { mapGetters } from "vuex";
const discussionViewSelectorMixin = {
  data() {
    return {
      isLoadingPostListFlag: false
    };
  },
  computed: {
    ...mapGetters(["getTopicId"])
  },
  created() {
    this.DISCUSSION_VIEW_SELECTOR = DISCUSSION_VIEW_SELECTOR;
  },
  methods: {
    getListOfPosts(page = 1) {
      this.$store.commit(
        "SET_DISCUSSION_VIEW_SELECTOR",
        this.DISCUSSION_VIEW_SELECTOR.PostsList
      );

      this.isLoadingPostListFlag = true;
      let obj = {
        course_id: this.$route.params.id,
        topic_id: this.getTopicId,
        page: page
      };
      this.$store.dispatch("getListOfPosts", obj).then(() => {
        this.isLoadingPostListFlag = false;
      });
    },
    imageUploadValidation(file) {
      const MAX_FILE_SIZE = 1 * 1024 * 1024; // 1MB
      const validFormats = ["image/jpg", "image/jpeg", "image/png"];
      const validExtensions = ["jpg", "jpeg", "png"];
      const fileExtension = file.name
        .split(".")
        .pop()
        .toLowerCase();

      if (file.size > MAX_FILE_SIZE) {
        return {
          isValid: false,
          errorMessage: this.$t("discussions.new_post.error.image_size")
        };
      }

      if (
        !validFormats.includes(file.type) ||
        !validExtensions.includes(fileExtension)
      ) {
        return {
          isValid: false,
          errorMessage: this.$t("discussions.new_post.error.image_format")
        };
      }

      return {
        isValid: true,
        errorMessage: ""
      };
    }
  }
};

export default discussionViewSelectorMixin;
